<div class="wrapper">
  <div class="wrapper__back"></div>
  <div class="wrapper__pop">
    <div class="wrapper__pop__section-one">
      <div class="wrapper__pop__section-one__logo">
        <img src="{{ landingBannerAssetsPath + 'icons/logo.svg' }}" alt="taager-logo" />
      </div>
      <div class="wrapper__pop__section-one__text caption1--bold">
        {{ 'LANDING.BANNER.TEXT_1' | translate }}
      </div>
    </div>
    <div class="wrapper__pop__section-two heading1--extra-bold">
      <span class="wrapper__pop__section-two__a heading1--extra-bold">{{
        'LANDING.BANNER.TEXT_2.a' | translate
      }}</span>
      <span class="wrapper__pop__section-two__b heading1--extra-bold">
        {{ 'LANDING.BANNER.TEXT_2.b' | translate }}</span
      >
      <span class="wrapper__pop__section-two__c heading1--extra-bold">{{
        'LANDING.BANNER.TEXT_2.c' | translate
      }}</span>
    </div>
    <div class="wrapper__pop__section-three body1--medium">
      {{ 'LANDING.BANNER.TEXT_3' | translate }}
    </div>
    <div
      class="wrapper__pop__section-four body1--bold"
      routerLink="{{ appURLs.REGISTER_V2_URL }}"
      (click)="onSignupClicked()"
    >
      {{ 'LANDING.BANNER.BUTTON_1' | translate }}
    </div>
    <div class="wrapper__pop__section-five">
      <div class="wrapper__pop__section-five__text body2--medium">
        {{ 'LANDING.BANNER.TEXT_4' | translate }}
      </div>
      <div class="wrapper__pop__section-five__countries">
        <img
          class="wrapper__pop__section-five__countries_img"
          src="{{ landingBannerAssetsPath + 'icons/egy.svg' }}"
          alt="egypt"
        />
        <img
          class="wrapper__pop__section-five__countries_img"
          src="{{ landingBannerAssetsPath + 'icons/ksa.svg' }}"
          alt="uae"
        />
        <img
          class="wrapper__pop__section-five__countries_img"
          src="{{ landingBannerAssetsPath + 'icons/uae.svg' }}"
          alt="ksa"
        />
      </div>
    </div>
    <img
      class="wrapper__pop__section-seven"
      src="{{ landingBannerAssetsPath + 'icons/check.svg' }}"
      alt="success"
    />
    <div class="wrapper__pop__section-six" #bannerAnimationElementRef></div>
  </div>
</div>
