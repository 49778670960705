<div class="services-container">
  <div class="services-container__header">
    <p class="services-container__header__text heading2--bold">
      {{ 'LANDING.SERVICES.PRE_HEADER' | translate }}
    </p>
  </div>
  <div class="services-container__body">
    <div class="services-container__body__steps">
      <div class="services-container__body__steps__header">
        <p class="services-container__body__steps__header__text heading2--bold">
          {{ 'LANDING.SERVICES.HEADER.BLACK_HEADER' | translate }}
          <span class="services-container__body__steps__header__blue heading2--bold">{{
            'LANDING.SERVICES.HEADER.BLUE_HEADER' | translate
          }}</span>
        </p>
      </div>
      <div class="services-container__body__steps__body">
        <div
          *ngFor="let cardContent of cardsContent"
          class="services-container__body__steps__body__card"
        >
          <img
            class="services-container__body__steps__body__card__image"
            src="{{ cardContent.image }}"
            alt="{{ cardContent.alt }}"
          />
          <p class="services-container__body__steps__body__card__header heading2--bold">
            {{ cardContent.header | translate }}
          </p>
          <p class="services-container__body__steps__body__card__text body2--regular">
            {{ cardContent.text | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="services-container__body__marketing">
      <div class="services-container__body__marketing__top">
        <div class="services-container__body__marketing__header">
          <img
            class="services-container__body__marketing__header__img"
            src="{{ assetsPath + 'Stars.svg' }}"
            alt="sparkling stars"
          />
          <p class="services-container__body__marketing__header__text heading2--bold">
            {{ 'LANDING.SERVICES.CARD.4.MAIN_HEADER' | translate }}
          </p>
        </div>
        <div>
          <img
            class="services-container__body__marketing__top__arrow"
            src="{{ assetsPath + 'Swing-arrow.svg' }}"
            alt="swing arrow"
          />
        </div>
      </div>
      <div class="services-container__body__marketing__card">
        <img
          class="services-container__body__marketing__card__img"
          src="{{ assetsPath + 'Marketing.svg' }}"
          alt="taager marketing services"
        />
        <p class="services-container__body__marketing__card__header heading2--bold">
          {{ 'LANDING.SERVICES.CARD.4.HEADER' | translate }}
        </p>
        <p class="services-container__body__marketing__card__text body2--regular">
          {{ 'LANDING.SERVICES.CARD.4.TEXT' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
