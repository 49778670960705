import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { LandingEventsService } from 'src/app/presentation/shared/containers/logged-out-layout/services/landing-events.service';
import { resolveUserLandingUtility } from 'src/app/presentation/shared/utilities/resolve-user-landing.utility';

@Component({
  selector: 'app-landing-banner',
  templateUrl: './landing-banner.component.html',
  styleUrls: ['./landing-banner.component.scss'],
  standalone: true,
  imports: [RouterLink, TranslateModule],
})
export class LandingBannerComponent implements OnInit, AfterViewInit {
  public landingBannerAssetsPath: string;

  public imageURLs: Array<string>;

  public selectedMarket: string;

  private _imageRotationDurationInSeconds: number;

  private _activeImageIndex: number;

  @ViewChild('bannerAnimationElementRef', { static: false })
  private _bannerAnimationElementRef: ElementRef<HTMLDivElement>;

  public activeImageObject: {
    url: string;
    alt: string;
  };

  constructor(
    private _renderer2: Renderer2,
    private _landingEventsService: LandingEventsService,
    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
  ) {
    this.landingBannerAssetsPath = '/assets/img/landing/banner/';
    this._imageRotationDurationInSeconds = 4000;
    this._activeImageIndex = 0;
  }

  ngAfterViewInit(): void {
    this._cycleBackgroundImage();
  }

  ngOnInit(): void {
    this._resolveSelectedMarket();
    if (this.selectedMarket === 'eg') {
      this.imageURLs = [
        `${this.landingBannerAssetsPath}characters/eg/Character1.webp`,
        `${this.landingBannerAssetsPath}characters/eg/Character2.webp`,
        `${this.landingBannerAssetsPath}characters/eg/Character3.webp`,
        `${this.landingBannerAssetsPath}characters/eg/Character4.webp`,
      ];
    } else {
      this.imageURLs = [
        `${this.landingBannerAssetsPath}characters/${this.selectedMarket}/Character1.webp`,
        `${this.landingBannerAssetsPath}characters/${this.selectedMarket}/Character2.webp`,
        `${this.landingBannerAssetsPath}characters/${this.selectedMarket}/Character3.webp`,
      ];
    }
  }

  private _resolveSelectedMarket(): void {
    this.selectedMarket = resolveUserLandingUtility().code;
  }

  private _cycleBackgroundImage(): void {
    this._applyStyleToElement(
      this._bannerAnimationElementRef.nativeElement,
      'background-image',
      `url(${this.imageURLs[this._activeImageIndex]})`,
    );
    setInterval(() => {
      if (this._activeImageIndex < this.imageURLs.length - 1) {
        this._activeImageIndex += 1;
      } else {
        this._activeImageIndex = 0;
      }
      const nextBackgroundImage = this.imageURLs[this._activeImageIndex];
      this._applyStyleToElement(
        this._bannerAnimationElementRef.nativeElement,
        'background-image',
        `url(${nextBackgroundImage})`,
      );
    }, this._imageRotationDurationInSeconds);
  }

  private _applyStyleToElement(element: HTMLElement, style: string, value: string): void {
    this._renderer2.setStyle(element, style, value);
  }

  onSignupClicked(): void {
    this._landingEventsService.sendLandingMixpanelEvent('sign_up_button_click', {
      $button_location: 'banner_section',
    });
    this._doLogSignupClickedOnGtag();
  }

  private async _doLogSignupClickedOnGtag(): Promise<void> {
    const payload = {
      'Button Location': 'banner',
    };
    this._landingEventsService.sendGtagMixpanelEvent('signup_button_click', payload);
  }
}
